export class ThreePhaseChargerOnSinglePhaseSiteAreaError extends Error {
  public constructor() {
    super('Three phase charger on single phase site area');
  }
}

export class ChargingStationNotUpdatedError extends Error {}

export class ChargingStationNotFoundError extends Error {
  public constructor() {
    super('Charging station not found');
  }
}

export class ChargingPointNotValidError extends Error {
  public constructor() {
    super('Charge point not valid');
  }
}

export class FeatureNotSupportedError extends Error {
  public constructor() {
    super('Feature not supported');
  }
}
