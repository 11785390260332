import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CancelReservationDto, ConnectorReservationDto } from '@api/charging-station/dto/connector-reservation.dto';
import { Observable } from 'rxjs';
import {
  CertificateHashData,
  DeleteCertificateCommandParam,
  GetDiagnosticsCommandParam,
  GetInstalledCertificatesIdsCommandParam,
  GetLogCommandParam,
  InstallCertificateCommandParam,
  OCPPGetConfigurationCommandResult,
  SendLocalListCommandParam,
  TriggerMessageCommandParam,
  UpdateFirmwareCommandParam,
} from 'types/ocpp/OCPP';
import { ChangeAvailabilityDto } from '@api/charging-station/dto/change-availability.dto';
import { ClearChargingProfilesRequest } from '@api/charging-station/dto/clear-charging-profile.dto';
import { ChargingStation, ChargingStationConfigurationMode, OcppParameter } from '../../types/ChargingStation';
import { ActionResponse, ChargingStationDataResult, DataResult } from '../../types/DataResult';
import { RESTServerRoute } from '../../types/Server';
import { buildUrl } from '../../utils/url/url.util';
import { Command, CommandBodyType } from './dto/charging-station.dto';

@Injectable()
export class ChargingStationApiService {
  public constructor(private httpClient: HttpClient) {}

  public getChargingStations(params: {
    Issuer?: boolean;
    WithSiteArea?: boolean;
    WithSite?: boolean;
    WithUser?: boolean;
    Limit?: number;
    SortFields?: string;
    SiteAreaID?: string;
  }): Observable<ChargingStationDataResult> {
    return this.httpClient.get<ChargingStationDataResult>(RESTServerRoute.REST_CHARGING_STATIONS, {
      params,
    });
  }

  public getExternalChargingStations(params: {
    Issuer?: boolean;
    WithSiteArea?: boolean;
    WithSite?: boolean;
    WithUser?: boolean;
    Limit?: number;
    SortFields?: string;
  }): Observable<ChargingStationDataResult> {
    return this.httpClient.get<ChargingStationDataResult>(RESTServerRoute.REST_EXTERNAL_CHARGING_STATIONS, {
      params,
    });
  }

  public getExternalChargingStation(id: string): Observable<ChargingStation> {
    return this.httpClient.get<ChargingStation>(buildUrl(RESTServerRoute.REST_EXTERNAL_CHARGING_STATION, { id }));
  }

  public getChargingStation(id: string): Observable<ChargingStation> {
    const params = { WithSite: true, WithSiteArea: true };
    return this.httpClient.get<ChargingStation>(buildUrl(RESTServerRoute.REST_CHARGING_STATION, { id }), { params });
  }

  public updateChargingStation(chargingStation: ChargingStation): Observable<ActionResponse> {
    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_UPDATE_PARAMETERS, {
      id: chargingStation.id,
    });
    return this.httpClient.put<ActionResponse>(url, chargingStation);
  }

  public switchChargingStationToConfigMode(
    id: string,
    mode: ChargingStationConfigurationMode,
  ): Observable<ActionResponse> {
    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_CONFIGURATION_MODE, {
      id: id,
      mode: mode,
    });
    return this.httpClient.put<ActionResponse>(url, {});
  }

  public getDiagnostics(chargerId: string, body: GetDiagnosticsCommandParam): Observable<ActionResponse> {
    const args = {
      args: body,
    };
    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_GET_DIAGNOSTICS, { id: chargerId });
    return this.httpClient.put<ActionResponse>(url, args);
  }

  public getLog(chargerId: string, body: GetLogCommandParam): Observable<ActionResponse> {
    const args = {
      args: body,
    };

    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_GET_LOG, { id: chargerId });
    return this.httpClient.put<ActionResponse>(url, args);
  }

  public updateFirmware(chargerId: string, body: UpdateFirmwareCommandParam): Observable<ActionResponse> {
    const args = {
      args: body,
    };
    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_FIRMWARE_UPDATE, { id: chargerId });
    return this.httpClient.put<ActionResponse>(url, args);
  }

  public deleteCertificate(chargerId: string, body: DeleteCertificateCommandParam): Observable<ActionResponse> {
    const args = {
      args: body,
    };
    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_DELETE_CERTIFICATE, { id: chargerId });
    return this.httpClient.put<ActionResponse>(url, args);
  }

  public installCertificate(chargerId: string, body: InstallCertificateCommandParam): Observable<ActionResponse> {
    const args = {
      args: body,
    };

    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_INSTALL_CERTIFICATE, { id: chargerId });
    return this.httpClient.put<ActionResponse>(url, args);
  }

  public getInstalledCertificatesIds(
    chargerId: string,
    body: GetInstalledCertificatesIdsCommandParam,
  ): Observable<ActionResponse> {
    const args = {
      args: body,
    };
    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_GET_INSTALLED_CERTIFICATE_IDS, { id: chargerId });
    return this.httpClient.put<ActionResponse>(url, args);
  }

  public sendLocalList(chargerId: string, body: SendLocalListCommandParam): Observable<ActionResponse> {
    const args = {
      args: body,
    };
    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_SEND_LOCAL_LIST, { id: chargerId });
    return this.httpClient.put<ActionResponse>(url, args);
  }

  public getLocalListVersion(chargerId: string): Observable<ActionResponse> {
    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_GET_LOCAL_LIST_VERSION, { id: chargerId });
    return this.httpClient.put<ActionResponse>(url, {});
  }

  public triggerMessage(chargerId: string, body: TriggerMessageCommandParam): Observable<ActionResponse> {
    const args = {
      args: body,
    };
    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_TRIGGER_MESSAGE, { id: chargerId });
    return this.httpClient.put<ActionResponse>(url, args);
  }

  public executeCommand(command: Command, chargerId: string, body?: CommandBodyType): Observable<ActionResponse> {
    switch (command) {
      case Command.GET_LOG:
        body['log'] = { remoteLocation: body['remoteLocation'] };
        delete body['remoteLocation'];
        return this.getLog(chargerId, body as GetLogCommandParam);
      case Command.GET_DIAGNOSTICS:
        return this.getDiagnostics(chargerId, body as GetDiagnosticsCommandParam);
      case Command.UPDATE_FIRMWARE:
        return this.updateFirmware(chargerId, body as UpdateFirmwareCommandParam);
      case Command.DELETE_CERTIFICATE:
        const newBody = { certificateHashData: body as CertificateHashData };
        return this.deleteCertificate(chargerId, newBody as DeleteCertificateCommandParam);
      case Command.GET_INSTALLED_CERTIFICATE_IDS:
        return this.getInstalledCertificatesIds(chargerId, body as GetInstalledCertificatesIdsCommandParam);
      case Command.INSTALL_CERTIFICATE:
        return this.installCertificate(chargerId, body as InstallCertificateCommandParam);
      case Command.SEND_LOCAL_LIST:
        if (body['localAuthorizationList']) {
          for (const localAuthorisation of (body as SendLocalListCommandParam).localAuthorizationList) {
            localAuthorisation['idTagInfo'] = { status: localAuthorisation.status };
          }
        }
        return this.sendLocalList(chargerId, body as SendLocalListCommandParam);
      case Command.GET_LOCAL_LIST_VERSION:
        return this.getLocalListVersion(chargerId);
      case Command.TRIGGER_MESSAGE:
        return this.triggerMessage(chargerId, body as TriggerMessageCommandParam);
      case Command.RESERVE_CONNECTOR:
        return this.reserveConnector(chargerId, body as ConnectorReservationDto);
      case Command.CANCEL_RESERVATION:
        return this.cancelReservation(chargerId, body as CancelReservationDto);
      case Command.CHANGE_AVAILABILITY:
        return this.changeAvailability(chargerId, body as ChangeAvailabilityDto);
      case Command.CLEAR_CHARGING_PROFILES:
        return this.clearChargingProfiles(chargerId, body as ClearChargingProfilesRequest);
      default:
        throw new Error(`Unknown command ${command}`);
    }
  }

  public retrieveChargingStationOcppParameter(
    chargingStationID: string,
    key: string,
  ): Observable<OCPPGetConfigurationCommandResult> {
    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_RETRIEVE_CONFIGURATION, {
      id: chargingStationID,
    });
    const body = {
      // We retrieve only one OCPP parameter
      args: { key: [key] },
    };
    return this.httpClient.put<OCPPGetConfigurationCommandResult>(url, body);
  }

  public reserveConnector(chargingStationId: string, data: ConnectorReservationDto): Observable<ActionResponse> {
    return this.httpClient.put<ActionResponse>(
      buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_RESERVE_NOW, { id: chargingStationId }),
      data,
    );
  }

  public cancelReservation(chargingStationId: string, data: CancelReservationDto): Observable<ActionResponse> {
    return this.httpClient.put<ActionResponse>(
      buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_CANCEL_RESERVATION, { id: chargingStationId }),
      data,
    );
  }

  public getOcppParameters(chargingStationID: string): Observable<DataResult<OcppParameter>> {
    return this.httpClient.get<DataResult<OcppParameter>>(
      buildUrl(RESTServerRoute.REST_CHARGING_STATION_GET_OCPP_PARAMETERS, { id: chargingStationID }),
    );
  }

  public changeAvailability(chargingStationID: string, data: ChangeAvailabilityDto): Observable<ActionResponse> {
    return this.httpClient.put<ActionResponse>(
      buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_CHANGE_AVAILABILITY, {
        id: chargingStationID,
      }),
      data,
    );
  }

  public clearChargingProfiles(
    chargingStationID: string,
    data: ClearChargingProfilesRequest,
  ): Observable<ActionResponse> {
    return this.httpClient.put<ActionResponse>(
      buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_CLEAR_CHARGING_PROFILES, {
        id: chargingStationID,
      }),
      { data },
    );
  }
}
